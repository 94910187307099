import { MouseEventHandler, useMemo } from 'react';

import { Icon20Like, Icon20LikeOutline } from '@vkontakte/icons';
import { Button, ButtonProps } from '@vkontakte/vkui';

type LikeButtonProps = {
  count: number;
  isLiked: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
} & ButtonProps;

export const LikeButton: React.FC<LikeButtonProps> = ({ count, isLiked, onClick, ...restProps }) => {
  const renderIcon = useMemo(() => {
    if (!isLiked) return <Icon20LikeOutline />;

    return <Icon20Like />;
  }, [isLiked]);

  return (
    <Button
      {...restProps}
      mode="tertiary"
      before={renderIcon}
      onClick={onClick}
    />
  );
};
