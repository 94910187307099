import React, { FC, HTMLAttributes, ElementType, CSSProperties, useState, useEffect } from 'react';

interface Props extends HTMLAttributes<Pick<HTMLElement, 'style'>> {
  as?: ElementType;
  mounted?: boolean;
}

const keep: CSSProperties = { display: 'none' };

export const KeepAlive: FC<Props> = ({ mounted, as: Component = 'div', ...restProps }) => {
  const [rendered, setRendered] = useState<boolean>(mounted);

  useEffect(() => {
    if (mounted) setRendered(true);
  }, [mounted]);

  if (!rendered) return null;

  return <Component {...restProps} style={mounted ? undefined : keep} />;
};
