import { atom } from 'recoil';
import { NormalizedPhoto } from 'types/photo';

export const feedAtom = atom<NormalizedPhoto[]>({
  key: 'feedAtom',
  default: [],
});

export const lastFeedCursorAtom = atom<string>({
  key: 'lastFeedCursorAtom',
  default: '',
});

export const exploreAtom = atom<NormalizedPhoto[]>({
  key: 'exploreAtom',
  default: [],
});

export const ownerAtom = atom<NormalizedPhoto[]>({
  key: 'ownerAtom',
  default: [],
});

export const exploreActiveAtom = atom<number|undefined>({
  key: 'exploreActiveAtom',
  default: undefined,
});

export const ownerActiveAtom = atom<number|undefined>({
  key: 'ownerActiveAtom',
  default: undefined,
});

export const ownerIdAtom = atom<number|undefined>({
  key: 'ownerIdAtom',
  default: undefined,
});
