import type { SnackbarProps } from '@vkontakte/vkui';
import { useState, useRef, useCallback, ReactNode, ReactElement } from 'react';
import { createContextComponent } from '../utils/createContextComponent';

export const { Provider: SnackbarProvider, hook: useSnackbar } = createContextComponent(() => {
  const [snackbar, setSnackbar] = useState<null | Partial<SnackbarProps> & JSX.IntrinsicAttributes>(null);
  const snakbarCount = useRef(0);
  const [popout, setPopout] = useState<ReactElement|null>(null);

  const showSnackbar = useCallback((content: ReactNode, props: Partial<SnackbarProps> = {}) => {
    setSnackbar({ key: snakbarCount.current++, ...props, children: content });
  }, []);

  const hideSnackbar = useCallback<VoidFunction>(() => {
    snackbar?.onClose && snackbar.onClose();
    setSnackbar(null);
  }, [snackbar]);

  return {
    snackbar, showSnackbar, hideSnackbar, popout, setPopout,
  };
});
