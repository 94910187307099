import { PanelHeader, Title, Button, PullToRefresh } from '@vkontakte/vkui';
import { Feed } from 'components';
import { useFriends, useFeed } from 'hooks';
import useInfiniteScroll from 'react-infinite-scroll-hook';

export const FeedPanel: React.FC = () => {
  const { friends } = useFriends();

  const friendIds = friends.map((friend) => friend.id);

  const { fetch, isLoading, hasMore, store, refresh } = useFeed(friendIds);

  const [loadRef] = useInfiniteScroll({
    onLoadMore: fetch,
    hasNextPage: hasMore,
    loading: isLoading,
  });

  return (
    <>
      <PanelHeader separator={false}>
        <Title weight="1" level="1">Шнапстер</Title>
      </PanelHeader>

      <PullToRefresh
        onRefresh={refresh}
        isFetching={isLoading}
      >
        <Feed
          feed={store}
        />

        {hasMore && (
          <Button
            getRootRef={loadRef}
            stretched
            size="l"
            loading={isLoading}
            mode="tertiary"
            onClick={fetch}
          >
            Загрузить ещё
          </Button>
        )}
      </PullToRefresh>
    </>
  );
};
