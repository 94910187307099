import { Post } from 'components';

import { useMemo } from 'react';
import { NormalizedPhoto } from 'types/photo';

type FeedProps = {
  feed: NormalizedPhoto[];
};

export const Feed: React.FC<FeedProps> = ({ feed }) => {
  const renderPosts = useMemo(() => (
    feed.length > 0 ? (
      feed.map((post) => (
        <Post
          key={post.photoId}
          post={post}
        />
      ))
    ) : null
  ), [feed]);

  return (
    <div className="Feed">
      {renderPosts}
    </div>
  );
};
