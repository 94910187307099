import React from 'react';
import type { BasePanelProps } from 'types/base';
import { Panel } from '@vkontakte/vkui';
import { useTabs } from 'hooks';
import { KeepAlive } from '@clickpick/shared';
import { FeedPanel, GlobalPanel, ActivityPanel, ProfilePanel } from 'panels';
import UploadPanel from '../UploadPanel/UploadPanel';

export const Home: React.FC<BasePanelProps> = (props) => {
  const { isFeedTab, isGlobalTab, isActivityTab, isProfileTab, isUploadTab } = useTabs();

  return (
    <Panel {...props} className="Home">
      <KeepAlive mounted={isFeedTab} as="section">
        <FeedPanel />
      </KeepAlive>
      <KeepAlive mounted={isGlobalTab} as="section">
        <GlobalPanel />
      </KeepAlive>
      <KeepAlive mounted={isUploadTab} as="section">
        <UploadPanel />
      </KeepAlive>
      <KeepAlive mounted={isActivityTab} as="section">
        <ActivityPanel />
      </KeepAlive>
      <KeepAlive mounted={isProfileTab} as="section">
        <ProfilePanel />
      </KeepAlive>

      <div className="Home__mockTabbar visibilityHidden" />
    </Panel>
  );
};
