import { pushPage } from '@happysanta/router';
import { Button, Div, FixedLayout, Panel, PanelHeader, Title, Text } from '@vkontakte/vkui';
import { ONBOARDING_TEXT } from 'const/onboarding';
import { useFriends, useLockBodyScroll, useMe } from 'hooks';
import { pages } from 'router';
import { BasePanelProps } from 'types/base';
import { useDoneOnboardMutation } from 'generated/graphql';

import bg1x from 'assets/onboarding/onboarding_1x.png';
import bg2x from 'assets/onboarding/onboarding_2x.png';
import bg3x from 'assets/onboarding/onboarding_3x.png';

const srcSet = `${bg1x} 1x, ${bg2x} 2x, ${bg3x} 3x`;

export const Onboarding: React.FC<BasePanelProps> = (props) => {
  const { getFriends, loading: friendsLoading } = useFriends();
  const { getMe, loading: meLoading } = useMe();
  const [doneOnboard] = useDoneOnboardMutation();

  useLockBodyScroll();

  const handleClick = () => {
    doneOnboard();
    getMe()
      .then(() => {
        getFriends()
          .then(() => pushPage(pages.MAIN))
          .catch((ex) => console.error(ex));
      })
      .catch((ex) => console.error(ex));
  };

  return (
    <Panel {...props} className="Onboarding">
      <PanelHeader className="Onboarding__header" separator={false}>
        <Title level="1" weight="1">Шнапстер</Title>
      </PanelHeader>
      <img src={bg1x} srcSet={srcSet} />
      <FixedLayout vertical="bottom">
        <Div className="Onboarding__content">
          <Title level="1" className="Onboarding__content-title">
            Добро пожаловать!
          </Title>
          <Text weight="regular" className="Onboarding__content-text">
            {ONBOARDING_TEXT}
          </Text>
          <Button
            stretched
            size="l"
            loading={meLoading || friendsLoading}
            onClick={handleClick}
          >
            Начать
          </Button>
        </Div>
      </FixedLayout>
    </Panel>
  );
};
