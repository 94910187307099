import { Button, PanelHeader, PullToRefresh, Title } from '@vkontakte/vkui';
import Grid from '../../components/Grid/Grid';
import { useExplorePhotos } from '../../hooks/useExplorePhotos';
import { FC } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useRouter } from '@happysanta/router';
import { pages } from '../../router';

export const GlobalPanel: FC = () => {
  const { fetch, store, hasMore, isLoading, setActivePhoto, refresh } = useExplorePhotos();
  const router = useRouter();

  const [loadRef] = useInfiniteScroll({
    onLoadMore: fetch,
    hasNextPage: hasMore,
    loading: isLoading,
  });

  const handleClick = (innerId: number) => {
    setActivePhoto(innerId);
    router.pushPage(pages.GLOBAL_FEED);
  };

  return (
    <>
      <PanelHeader separator={false}>
        <Title weight="1" level="1">Шнапстер</Title>
      </PanelHeader>
      <PullToRefresh
        onRefresh={refresh}
        isFetching={isLoading}
      >
        <Grid photos={store} onClick={handleClick} />
        {hasMore && (
          <Button
            getRootRef={loadRef}
            stretched
            size="l"
            loading={isLoading}
            mode="tertiary"
            onClick={() => fetch()}
          >
            Загрузить ещё
          </Button>
        )}
      </PullToRefresh>
    </>
  );
};
