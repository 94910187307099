import React, { FC } from 'react';
import './Grid.css';
import { NormalizedPhoto } from '../../types/photo';

type Props = {
  photos: NormalizedPhoto[];
  onClick?: (innerId: number) => void;
};

const Grid: FC<Props> = ({ photos, onClick }) => {
  return (
    <div className="Grid">
      {photos.map((photo) => {
        return (
          <div className="Grid__item" key={photo.innerId} onClick={() => onClick && onClick(photo.innerId)}>
            <img className="Grid__item-img" src={photo.photoUrl} />
          </div>
        );
      })}
    </div>
  );
};

export default Grid;
