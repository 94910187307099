import { Post } from 'components';

import { useMemo } from 'react';
import { NormalizedPhoto } from 'types/photo';

type FeedProps = {
  feed: NormalizedPhoto[];
  activePhoto?: number;
};

export const FeedShort: React.FC<FeedProps> = ({ feed, activePhoto }) => {
  const renderPosts = useMemo(() => {
    let customFeed = feed;

    if (activePhoto) {
      const photoFound = feed.find((photo) => {
        return photo.innerId === activePhoto;
      });

      let add = false;
      if (photoFound) {
        customFeed = customFeed.filter((photo) => {
          if (!add && photo.innerId !== activePhoto) {
            return false;
          }
          add = true;
          return true;
        });
      }
    }

    return customFeed.length > 0 ? (
      customFeed.map((post) => (
        <Post data-inner-id={post.innerId}
          key={post.photoId}
          post={post}
        />
      ))
    ) : null;
  }, [feed, activePhoto]);

  return (
    <div className="Feed">
      {renderPosts}
    </div>
  );
};
