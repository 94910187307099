import { useFeedLazyQuery } from 'generated/graphql';
import { useRecoilState } from 'recoil';
import { feedAtom } from 'store';
import { useRef, useState } from 'react';
import { usePhotos } from './usePhotos';

export const useFeed = (friendIds: number[]) => {
  const [loadFeed] = useFeedLazyQuery();
  const [feedStore, setFeedStore] = useRecoilState(feedAtom);
  const feedCursor = useRef<string|undefined>(undefined);
  const [feedHasMore, setFeedHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { fillPhotos } = usePhotos();

  const FETCH_COUNT = 20;

  const fetchFeed = () => {
    setIsLoading(true);
    loadFeed({
      variables: {
        friendIds: friendIds,
        page: {
          first: FETCH_COUNT,
          after: feedCursor.current,
        },
      },
    }).then(async (res) => {
      const nodes = res.data.feed.edges.map((edge) => {
        return edge.node;
      });

      if (res.data.feed.edges.length < FETCH_COUNT) {
        setFeedHasMore(false);

        if (res.data.feed.edges.length === 0) {
          return;
        }
      }

      feedCursor.current = res.data.feed.edges[res.data.feed.edges.length - 1].cursor;

      const normalized = await fillPhotos(nodes);

      setFeedStore((old) => {
        const used = new Set();
        return [
          ...old,
          ...normalized,
        ].filter((photo) => {
          if (used.has(photo.innerId)) {
            return false;
          }
          used.add(photo.innerId);
          return true;
        });
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const refresh = () => {
    setFeedStore([]);
    feedCursor.current = undefined;
    setFeedHasMore(true);
    fetchFeed();
  };

  return {
    fetch: fetchFeed,
    store: feedStore,
    hasMore: feedHasMore,
    isLoading,
    refresh,
  };
};
