import { Avatar, Div, Subhead, Text } from '@vkontakte/vkui';
import { LikeButton, PostDescription } from 'components';

import { Icon16Recent } from '@vkontakte/icons';
import { NormalizedPhoto } from 'types/photo';
import { useLikes } from 'hooks';
import { HTMLAttributes } from 'react';
import { useRouter } from '@happysanta/router';
import { modals, pages, pushModal } from 'router';
import { getFormatDate } from 'utils/date';

type PostProps = HTMLAttributes<HTMLDivElement> & {
  post: NormalizedPhoto;
};

export const Post: React.FC<PostProps> = ({ post, ...props }) => {
  const { likes, isLiked, handleReaction } = useLikes(post);
  const router = useRouter();

  const handleUserClick = () => {
    router.pushPage(pages.OWNER, {
      id: String(post.user.id),
    });
  };

  return (
    <div className="Post" {...props}>
      <Div className="Post__header">
        <div className="Post__user" onClick={handleUserClick}>
          <Avatar
            size={40}
            src={post.user.avatarUrl}
          />
          <Text weight="semibold">
            {`${post.user.firstName} ${post.user.lastName}`}
          </Text>
        </div>
        <div className="Post__createdAt">
          <Icon16Recent />
          <Subhead weight="2">{getFormatDate(post.date)}</Subhead>
        </div>
      </Div>
      <img
        className="Post__img"
        onDoubleClick={() => handleReaction()}
        src={post.photoUrl}
      />
      <div className="Post__likes">
        <LikeButton
          className="Post__like"
          count={likes}
          isLiked={isLiked}
          onClick={handleReaction}
        />
        <Subhead
          weight="1"
          className="Post__like-counter"
          onClick={() => pushModal(modals.LIKES, { id: post.innerId.toString() })}
        >
          {`${likes} понравилось`}
        </Subhead>
      </div>
      <PostDescription post={post} />
    </div>
  );
};
