import { FC, useState } from 'react';
import { PanelSpinner } from '@vkontakte/vkui';
import { useMeQuery } from 'generated/graphql';
import { replacePage } from '@happysanta/router';
import { pages } from 'router';
import { useSetRecoilState } from 'recoil';
import { userBackAtom } from '../store';
import { useFriends, useMe } from 'hooks';

/**
 * @todo обработать onError
 */

export const AppLoader: FC = (props) => {
  const [ready, setReady] = useState(false);
  const setBackUser = useSetRecoilState(userBackAtom);

  const { getMe } = useMe();
  const { getFriends } = useFriends();

  useMeQuery({
    onCompleted: (data) => {
      setBackUser(data.me);

      if (!data.me.isOnboardDone) {
        replacePage(pages.ONBOARDING);
        setReady(true);
      } else {
        getMe()
          .then(
            () => getFriends()
              .then(() => setReady(true))
          );
      }
    },
    onError: () => {
      // todo
    },
  });

  if (ready) return <>{props.children}</>;

  return <PanelSpinner style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#fff' }} />;
};
