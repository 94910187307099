import { Tabbar, TabbarItem, TabbarProps } from '@vkontakte/vkui';

import { MouseEvent } from 'react';
import { Tab, TabType } from 'types/tab';

// Icons
import { ReactComponent as HomeIcon } from 'assets/home.svg';
import { ReactComponent as GlobalIcon } from 'assets/global.svg';
import { ReactComponent as UploadIcon } from 'assets/upload.svg';
import { ReactComponent as ActivityIcon } from 'assets/activity.svg';
import { ReactComponent as ProfileIcon } from 'assets/profile.svg';

type InstTabbar = {
  selected: string;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
} & TabbarProps;

export const InstTabbar: React.FC<InstTabbar> = ({ selected, onClick, ...restProps }) => {
  const isSelected = (tab: TabType): boolean => selected === tab;

  return (
    <Tabbar
      {...restProps}
    >
      <TabbarItem
        data-tab={Tab.FEED}
        selected={isSelected(Tab.FEED)}
        onClick={onClick}
        className={isSelected(Tab.FEED) ? 'InstTabbar__item-active' : 'InstTabbar__item'}
      >
        <HomeIcon />
      </TabbarItem>
      <TabbarItem
        data-tab={Tab.GLOBAL}
        selected={isSelected(Tab.GLOBAL)}
        onClick={onClick}
        className={isSelected(Tab.GLOBAL) ? 'InstTabbar__item-active' : 'InstTabbar__item'}
      >
        <GlobalIcon />
      </TabbarItem>
      <TabbarItem
        data-tab={Tab.UPLOAD}
        selected={isSelected(Tab.UPLOAD)}
        onClick={onClick}
        className="InstTabbar__item-upload"
      >
        <UploadIcon />
      </TabbarItem>
      <TabbarItem
        data-tab={Tab.ACTIVITY}
        selected={isSelected(Tab.ACTIVITY)}
        onClick={onClick}
        className={isSelected(Tab.ACTIVITY) ? 'InstTabbar__item-active' : 'InstTabbar__item'}
      >
        <ActivityIcon />
      </TabbarItem>
      <TabbarItem
        data-tab={Tab.PROFILE}
        selected={isSelected(Tab.PROFILE)}
        onClick={onClick}
        className={isSelected(Tab.PROFILE) ? 'InstTabbar__item-active' : 'InstTabbar__item'}
      >
        <ProfileIcon />
      </TabbarItem>
    </Tabbar>
  );
};
