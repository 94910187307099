import { Avatar, Button, Caption, Title } from '@vkontakte/vkui';
import { Me } from 'types/me';

import { Icon28ChevronRightOutline, Icon28LogoVk } from '@vkontakte/icons';

type ProfileInfoProps = {
  user: Me;
  friendsCount: number;
  photosCount: number;
  likesCount: number;
  isMe?: boolean;
  onShare?: () => void;
};

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ user, friendsCount, photosCount, likesCount, isMe, onShare }) => {
  return (
    <div className="ProfileInfo">
      <div className="ProfileInfo__avatar">
        <Avatar
          size={100}
          src={user.photo_200}
          mode="app"
        />
      </div>
      <div className="ProfileInfo__col">
        <div className="ProfileInfo__counters">
          <div className="ProfileInfo__counter">
            <Title level="2" weight="semibold">
              {photosCount}
            </Title>
            <Caption weight="regular" level="1">
              фото
            </Caption>
          </div>
          <div className="ProfileInfo__counter">
            <Title level="2" weight="semibold">
              {likesCount}
            </Title>
            <Caption weight="regular" level="1">
              лайков
            </Caption>
          </div>
          {friendsCount > 0 && (
            <div className="ProfileInfo__counter">
              <Title level="2" weight="semibold">
                {friendsCount}
              </Title>
              <Caption weight="regular" level="1">
                друзей
              </Caption>
            </div>
          )}
        </div>
        {isMe ? (
          <div className="ProfileInfo__share">
            <Button
              mode="tertiary"
              stretched
              size="l"
              onClick={onShare}
              after={<Icon28ChevronRightOutline />}
            >
              Поделиться
            </Button>
          </div>
        ) : (
          <div className="ProfileInfo__share ProfileInfo__share--vk">
            <Button
              mode="tertiary"
              size="l"
              href={`https://vk.com/id${user.id}`}
              target="_blank"
              before={<Icon28LogoVk />}
            >
              Открыть профиль
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
