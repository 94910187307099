import { Tabs, TabsItem } from '@vkontakte/vkui';

import { Icon28AllCategoriesOutline, Icon28Menu } from '@vkontakte/icons';

type ViewSelectorProps = {
  selected: 'feed' | 'grid';
  onSelect: (value: 'feed' | 'grid') => void;
};

export const ViewSelector: React.FC<ViewSelectorProps> = ({ selected, onSelect }) => {
  return (
    <Tabs
      mode="buttons"
      className="ViewSelector"
    >
      <TabsItem
        selected={selected === 'grid'}
        onClick={() => onSelect('grid')}
      >
        <Icon28AllCategoriesOutline />
      </TabsItem>
      <TabsItem
        selected={selected === 'feed'}
        onClick={() => onSelect('feed')}
      >
        <Icon28Menu />
      </TabsItem>
    </Tabs>
  );
};
