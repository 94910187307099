import { useExploreLazyQuery } from '../generated/graphql';
import { useRecoilState } from 'recoil';
import { exploreActiveAtom, exploreAtom } from '../store';
import { useRef, useState } from 'react';
import { usePhotos } from './usePhotos';

export const useExplorePhotos = () => {
  const [loadExplore] = useExploreLazyQuery();
  const [exploreStore, setExploreStore] = useRecoilState(exploreAtom);
  const exploreCursor = useRef<string|undefined>(undefined);
  const [exploreHasMore, setExploreHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { fillPhotos } = usePhotos();
  const [activePhoto, setActivePhoto] = useRecoilState(exploreActiveAtom);

  const FETCH_COUNT = 20;

  const fetchExplore = () => {
    setIsLoading(true);
    loadExplore({
      variables: {
        page: {
          first: FETCH_COUNT,
          after: exploreCursor.current,
        },
      },
    }).then(async (res) => {
      const nodes = res.data.explore.edges.map((edge) => {
        return edge.node;
      });

      if (res.data.explore.edges.length < FETCH_COUNT) {
        setExploreHasMore(false);

        if (res.data.explore.edges.length === 0) {
          return;
        }
      }

      exploreCursor.current = res.data.explore.edges[res.data.explore.edges.length - 1].cursor;

      const normalized = await fillPhotos(nodes);

      setExploreStore((old) => {
        const used = new Set();
        return [
          ...old,
          ...normalized,
        ].filter((photo) => {
          if (used.has(photo.innerId)) {
            return false;
          }
          used.add(photo.innerId);
          return true;
        });
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const refresh = () => {
    setActivePhoto(undefined);
    exploreCursor.current = undefined;
    setExploreStore([]);
    setExploreHasMore(true);
    fetchExplore();
  };

  return {
    fetch: fetchExplore,
    store: exploreStore,
    hasMore: exploreHasMore,
    isLoading,
    activePhoto,
    setActivePhoto,
    refresh,
  };
};
