import { atom } from 'recoil';
import { Me } from 'types/me';
import { MeQuery } from '../generated/graphql';

export const userAtom = atom<Me>({
  key: 'user',
  default: null,
});

export const userBackAtom = atom<MeQuery['me']>({
  key: 'userBack',
  default: null,
});
