import { BridgePlus } from '@happysanta/bridge-plus';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { friendsAtom } from 'store';

export const useFriends = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [friendsStore, setFriendsStore] = useRecoilState(friendsAtom);

  const getFriends = () => {
    return new Promise((resolve, reject) => {
      if (!friendsStore) {
        setLoading(true);

        BridgePlus.api('friends.get', {
          fields: 'photo_100',
          order: 'hints',
        })
          .then(({ response }) => {
            console.info(response);

            setFriendsStore(response.items);

            resolve(response);
          })
          .catch((ex) => reject(ex))
          .finally(() => setLoading(false));
      }
    });
  };

  return {
    friends: friendsStore,
    getFriends,
    loading,
  };
};
