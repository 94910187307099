import { BridgePlus } from '@happysanta/bridge-plus';
import { Photo, useAttachAlbumMutation, useAttachPhotoMutation } from '../generated/graphql';
import { AttachedPhoto, NormalizedPhoto, PhotoResponse, PhotoUser } from '../types/photo';

export const usePhotos = () => {
  const [attachAlbum] = useAttachAlbumMutation();
  const [attachPhoto] = useAttachPhotoMutation();

  const attachUserAlbum = () => {
    return BridgePlus.api('photos.createAlbum', {
      title: 'Shnapster',
    }).then((res) => {
      return res.response.id;
    }).then((id) => {
      return attachAlbum({
        variables: {
          id,
        },
      }).then(() => {
        return id;
      });
    });
  };

  const attachUserPhoto = (albumId: number, file: File): Promise<AttachedPhoto> => {
    return BridgePlus.api('photos.getUploadServer', {
      album_id: albumId,
    }).then((res) => {
      return res.response.upload_url;
    }).then((url) => {
      return new Promise(((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        const corsUrl = `https://vk-cors.mini-team.space/${url}`;

        xhr.open('POST', corsUrl, true);
        xhr.responseType = 'json';
        xhr.onload = () => {
          resolve(xhr.response);
        };
        xhr.onabort = reject;
        xhr.onerror = reject;

        const data = new FormData();

        data.append('photo', file);

        xhr.send(data);
      }));
    }).then((res: any) => {
      return BridgePlus.api('photos.save', {
        ...res,
        album_id: albumId,
      });
    }).then((res) => {
      const item = res.response[0];

      return attachPhoto({
        variables: {
          id: item.id,
        },
      }).then(() => {
        return {
          albumId: albumId,
          photoId: item.id,
          url: item.sizes?.find((i: any) => {
            return i.type === 'r';
          })?.url,
        };
      });
    });
  };

  const updateCaption = (photoId: number, caption: string) => {
    return BridgePlus.api('photos.edit', {
      photo_id: photoId,
      caption,
    });
  };

  const fillPhotos = (input: Photo[]): Promise<NormalizedPhoto[]> => {
    const ownerIds = input.map((photo) => {
      return photo.ownerId;
    });

    const photoIds = input.map((photo) => {
      return `${photo.ownerId}_${photo.photoId}`;
    });

    return Promise.all([
      BridgePlus.api('users.get', { user_ids: ownerIds.join(','), fields: 'screen_name,photo_200' }),
      BridgePlus.api('photos.getById', { photos: photoIds.join(','), fields: 'caption' }),
    ]).then(([usersRes, photosRes]) => {
      const userMap: Record<number, PhotoUser> = {};
      const photoMap: Record<string, PhotoResponse> = {};

      usersRes.response.forEach((user: any) => {
        userMap[user.id] = {
          id: user.id,
          firstName: user.first_name,
          lastName: user.last_name,
          avatarUrl: user.photo_200,
          screenName: user.screen_name,
        };
      });

      photosRes.response.forEach((photo: any) => {
        photoMap[`${photo.owner_id}_${photo.id}`] = {
          id: photo.id,
          ownerId: photo.owner_id,
          caption: photo.text,
          date: photo.date,
          photoUrl: photo.sizes.find((i: any) => {
            return i.type === 'r';
          }).url,
          sizes: photo.sizes,
        };
      });

      const res: NormalizedPhoto[] = [];

      input.forEach((photo) => {
        const u = userMap[photo.ownerId];
        const p = photoMap[`${photo.ownerId}_${photo.photoId}`];

        if (!u || !p) {
          return;
        }

        res.push({
          innerId: photo.innerId,
          ownerId: photo.ownerId,
          photoId: photo.photoId,
          likesCount: photo.likesCount,
          isLiked: photo.isLiked,
          user: {
            id: u.id,
            firstName: u.firstName,
            lastName: u.lastName,
            screenName: u.screenName,
            avatarUrl: u.avatarUrl,
          },
          photoUrl: p.photoUrl,
          sizes: p.sizes,
          date: new Date(p.date * 1000),
          caption: p.caption,
        });
      });

      console.log(res);

      return res;
    });
  };

  return {
    attachUserAlbum,
    attachUserPhoto,
    updateCaption,
    fillPhotos,
  };
};
