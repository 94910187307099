import { Button, Div, Group, Panel, PanelHeader, PanelHeaderBack, Placeholder, PullToRefresh, Title } from '@vkontakte/vkui';
import { KeepAlive, ProfileInfo, ViewSelector } from 'components';
import { FC, useEffect, useState } from 'react';
import { useOwnerPhotos } from '../../hooks/useOwnerPhotos';
import { FeedShort } from '../../components/FeedShort/Feed';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Grid from '../../components/Grid/Grid';
import { useFirstPageCheck, useLocation, useRouter } from '@happysanta/router';
import { BasePanelProps } from '../../types/base';
import { pages } from '../../router';
import { useFriends, useMe } from '../../hooks';
import { BridgePlus } from '@happysanta/bridge-plus';
import { OwnerInfo, useOwnerInfoLazyQuery } from '../../generated/graphql';

export const OwnerPanel: FC<BasePanelProps> = ({ ...props }) => {
  const location = useLocation();
  const router = useRouter();
  const isFirstPage = useFirstPageCheck();
  const [fetchUserInfo] = useOwnerInfoLazyQuery();
  const [userInfo, setUserInfo] = useState<OwnerInfo|undefined>(undefined);

  const ownerId = location.route.getParams().id;
  const { me } = useMe();
  const friends = useFriends();

  const [ownerUser, setOwnerUser] = useState(undefined);

  useEffect(() => {
    if (me && ownerId) {
      let uId;
      let fr;
      if (Number(ownerId) !== me.id) {
        BridgePlus.api('users.get', {
          user_ids: ownerId,
          fields: 'photo_200,screen_name',
        }).then((res) => {
          const u = res.response[0];
          console.log(u);
          setOwnerUser(u);
        });
        uId = ownerId;
      } else {
        setOwnerUser(me);
        uId = me.id;
        fr = friends.friends.map((f) => {
          return f.id;
        });
      }

      fetchUserInfo({
        variables: {
          ownerId: Number(uId),
          friendIds: fr ?? [],
        },
      }).then((res) => {
        setUserInfo(res.data.ownerInfo);
      });
    }
  }, [me, ownerId]);

  const [tab, setTab] = useState<'grid' | 'feed'>('grid');
  const { store, hasMore, fetch, isLoading, refresh } = useOwnerPhotos(Number(ownerId));

  const [loadRef] = useInfiniteScroll({
    onLoadMore: fetch,
    hasNextPage: hasMore,
    loading: isLoading,
  });

  useEffect(() => {
    fetch();
  }, []);

  const handleGridClick = (innerId: number) => {
    if (BridgePlus.supports('VKWebAppShowImages')) {
      const index = store.findIndex((photo) => {
        return photo.innerId === innerId;
      });

      BridgePlus.send('VKWebAppShowImages', {
        images: store.map((photo) => {
          return photo.photoUrl;
        }),
        start_index: index,
      });
    }
  };

  return (
    <Panel {...props}>
      <PanelHeader className="Profile__header" left={
        <PanelHeaderBack
          onClick={() => {
            if (isFirstPage) {
              router.replacePage(pages.MAIN);
            } else {
              router.popPage();
            }
          }}
        />
      }>
        <Title level="1" weight="1">{ownerUser && ownerUser.screen_name}</Title>
      </PanelHeader>
      <PullToRefresh onRefresh={refresh} isFetching={isLoading}>
        <Div>
          {ownerUser && userInfo && (
            <ProfileInfo
              user={ownerUser}
              friendsCount={userInfo.friends}
              photosCount={userInfo.photos}
              likesCount={userInfo.likes}
            />
          )}
          <Group separator="hide">
            <ViewSelector
              selected={tab}
              onSelect={(value) => setTab(value)}
            />
          </Group>
          <KeepAlive mounted={tab === 'feed'} as="section">
            {store.length === 0 && !hasMore ? (
              <Placeholder>
                Пока здесь ничего нет
              </Placeholder>
            ) : (
              <FeedShort
                feed={store}
              />
            )}
            {hasMore && (
              <Button
                getRootRef={loadRef}
                stretched
                size="l"
                loading={isLoading}
                mode="tertiary"
                onClick={() => fetch()}
              >
                Загрузить ещё
              </Button>
            )}
          </KeepAlive>

          <KeepAlive mounted={tab === 'grid'} as="section">
            {store.length === 0 && !hasMore ? (
              <Placeholder>
                Пока здесь ничего нет
              </Placeholder>
            ) : (
              <Grid
                photos={store}
                onClick={handleGridClick}
              />
            )}
            {hasMore && (
              <Button
                getRootRef={loadRef}
                stretched
                size="l"
                loading={isLoading}
                mode="tertiary"
                onClick={() => fetch()}
              >
                Загрузить ещё
              </Button>
            )}
          </KeepAlive>
        </Div>
      </PullToRefresh>
    </Panel>
  );
};
