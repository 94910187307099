import { BridgePlus } from '@happysanta/bridge-plus';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { userAtom } from 'store';

export const useMe = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [userStore, setUserStore] = useRecoilState(userAtom);

  const getMe = () => {
    return new Promise((resolve, reject) => {
      if (!userStore) {
        setLoading(true);

        BridgePlus.api('users.get', {
          fields: 'photo_200,screen_name',
        })
          .then(({ response }) => {
            setUserStore(response[0]);

            resolve(userStore);
          })
          .catch((ex) => reject(ex))
          .finally(() => setLoading(false));
      }

      resolve(userStore);
    });
  };

  return {
    me: userStore,
    getMe,
    loading,
  };
};
