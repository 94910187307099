import { Page, Router, setGlobalRouter } from '@happysanta/router';

export const modals = {
  LIKES: 'modal_likes',
} as const;

export const popouts = {} as const;

export const panels = {
  HOME: 'panel_home',
  GLOBAL: 'panel_global',
  GLOBAL_FEED: 'panel_global_feed',
  ACTIVITY: 'panel_activity',
  PROFILE: 'panel_profile',
  UPLOAD: 'panel_upload',

  OWNER: 'panel_owner',

  ONBOARDING: 'panel_onboarding',
} as const;

export const views = {
  MAIN: 'view_main',
  OWNER: 'owner',

  /* Onboarding */
  ONBOARDING: 'view_onboarding',
} as const;

export const pages = {
  MAIN: '/',

  /* Onboarding */
  ONBOARDING: '/onboard',
  GLOBAL_FEED: '/global-feed',
  OWNER: '/profile/:id([0-9]+)',
} as const;

const routes = {
  [pages.MAIN]: new Page(panels.HOME, views.MAIN),
  [pages.GLOBAL_FEED]: new Page(panels.GLOBAL_FEED, views.MAIN),

  /* Onboarding */
  [pages.ONBOARDING]: new Page(panels.ONBOARDING, views.ONBOARDING),

  [pages.OWNER]: new Page(panels.OWNER, views.OWNER),
};

export const router = new Router(routes);

setGlobalRouter(router);

export const pushPage: Router['pushPage'] = (...args) => router.pushPage(...args);
export const popPage: Router['popPage'] = (...args) => router.popPage(...args);
export const popPageCallback = () => popPage();
export const popPageTo: Router['popPageTo'] = (...args) => router.popPageTo(...args);
export const popPageIfModal: Router['popPageIfModal'] = (...args) => router.popPageIfModal(...args);
export const replacePage: Router['replacePage'] = (...args) => router.replacePage(...args);

export const pushModal: Router['pushModal'] = (...args) => router.pushModal(...args);
export const replaceModal: Router['replaceModal'] = (...args) => router.replaceModal(...args);

export const pushPopout: Router['pushPopup'] = (...args) => router.pushPopup(...args);

export const popPageToAndReplace: Router['popPageToAndReplace'] = (...args) => router.popPageToAndReplace(...args);
