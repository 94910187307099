import React, { ChangeEventHandler, useState } from 'react';
import { Button, Div, File, FormItem, PanelHeader, Placeholder, Spinner, Text, Textarea, Title } from '@vkontakte/vkui';
import { Icon24Camera } from '@vkontakte/icons';
import { useRecoilState } from 'recoil';
import { userBackAtom } from '../../store';
import { usePhotos } from '../../hooks/usePhotos';
import { AttachedPhoto } from '../../types/photo';
import './UploadPanel.css';
import { useSnackbar } from '../../context/Snackbar';
import { useTabs } from '../../hooks';
import { Tab } from '../../types/tab';
import { useOwnerPhotos } from '../../hooks/useOwnerPhotos';

const UploadPanel = () => {
  const { pushTabByName } = useTabs();
  const { showSnackbar } = useSnackbar();
  const [me, setMe] = useRecoilState(userBackAtom);
  const photos = usePhotos();
  const [attachedPhoto, setAttachedPhoto] = useState<AttachedPhoto|undefined>(undefined);
  const [caption, setCaption] = useState<string|undefined>(undefined);
  const [photoIsUploading, setPhotoIsUploading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { refresh } = useOwnerPhotos(me.id);

  const handleSubmit = async () => {
    if (caption) {
      const res = await photos.updateCaption(attachedPhoto.photoId, caption);
      console.log(res);
    }

    setAttachedPhoto(undefined);
    setCaption(undefined);
    refresh().then(() => {
      showSnackbar('Фото добавлено', {
        action: 'Перейти в профиль',
        onActionClick: () => {
          pushTabByName(Tab.PROFILE);
        },
      });
    });
  };

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        setPhotoIsUploading(true);
        setIsError(false);
        const file = e.target.files[0];

        let albumId = me.albumId;

        if (!albumId) {
          albumId = await photos.attachUserAlbum();

          setMe((old) => {
            return {
              ...old,
              albumId,
            };
          });
        }

        const res = await photos.attachUserPhoto(albumId, file);
        setAttachedPhoto(res);
        setPhotoIsUploading(false);
      }
    } catch (er) {
      setPhotoIsUploading(false);
      setIsError(true);
    }
  };

  return (
    <>
      <PanelHeader>
        <Title level="1" weight="1">Шнапстер</Title>
      </PanelHeader>
      {attachedPhoto ? (
        <Div>
          <div className="UploadPanel__img-wrapper">
            <img className="UploadPanel__img" alt="attached photo" src={attachedPhoto.url} />
          </div>
          <FormItem top="Введи описание">
            <Textarea defaultValue={caption} onChange={(e) => setCaption(e.target.value)} />
          </FormItem>
          <FormItem>
            <Button stretched onClick={handleSubmit}>Готово</Button>
          </FormItem>
        </Div>
      ) : (
        <Placeholder stretched>
          {photoIsUploading ? (
            <Spinner />
          ) : (
            <div>
              <File before={<Icon24Camera />} controlSize="m" onChange={handleFileChange}>
                Открыть галерею
              </File>
              {isError && (
                <Text weight="semibold">
                  Произошла ошибка
                </Text>
              )}
            </div>
          )}
        </Placeholder>
      )}
    </>
  );
};

export default UploadPanel;
