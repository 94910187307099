import { useMemo, useState } from 'react';

import { BridgePlus } from '@happysanta/bridge-plus';
import { useParams } from '@happysanta/router';
import { Icon24Dismiss } from '@vkontakte/icons';
import { Avatar, List, ModalPage, ModalPageHeader, PanelHeaderButton, PanelSpinner, SimpleCell } from '@vkontakte/vkui';

import { useLikerIdsQuery } from 'generated/graphql';

import { BaseModalPageProps } from 'types/base';
import { LikerUser } from 'types/likerUsers';
import { pages, pushPage } from 'router';

export const LikesModal: React.FC<BaseModalPageProps> = (props) => {
  const [users, setUsers] = useState<LikerUser[]>();

  const { id } = useParams();

  const { loading } = useLikerIdsQuery({
    variables: { innerId: Number(id) },
    onCompleted: ({ likerIds }) => {
      if (likerIds.length === 0) return;

      const userIds = likerIds.join(',');

      BridgePlus.api('users.get', {
        user_ids: userIds,
        fields: 'photo_100',
      })
        .then((response) => {
          setUsers(response.response);
        })
        .catch((ex) => console.error('[LikerUsers]', ex));
    },
  });

  const renderUsers = useMemo(() => (
    users && users.map((user) => (
      <SimpleCell
        key={user.id}
        before={
          <Avatar size={40} src={user.photo_100} />
        }
        onClick={() => pushPage(pages.OWNER, { id: user.id.toString() })}
      >
        {`${user.first_name} ${user.last_name}`}
      </SimpleCell>
    ))
  ), [users]);

  if (loading || !users) {
    return (
      <ModalPage
        {...props}
        dynamicContentHeight
      >
        <PanelSpinner size="medium" />
      </ModalPage>
    );
  }

  return (
    <ModalPage
      {...props}
      dynamicContentHeight
      className="CustomModalPage"
    >
      <ModalPageHeader
        right={
          <PanelHeaderButton onClick={props.onClose}>
            <Icon24Dismiss />
          </PanelHeaderButton>
        }
      >
        Отметки нравится
      </ModalPageHeader>
      <List className="LikesModal__list">
        {renderUsers}
      </List>
    </ModalPage>
  );
};
