import { PanelHeader, Placeholder, Title } from '@vkontakte/vkui';

import { Icon56RecentOutline } from '@vkontakte/icons';

export const ActivityPanel: React.FC = () => {
  return (
    <>
      <PanelHeader separator={false}>
        <Title weight="1" level="1">Шнапстер</Title>
      </PanelHeader>
      <Placeholder
        stretched
        icon={<Icon56RecentOutline />}
        header="Раздел ещё не готов"
      >
        Будет позже...
      </Placeholder>
    </>
  );
};
