import type { FC } from 'react';
import { ApolloProvider } from '@apollo/client';
import { RouterContext } from '@happysanta/router';
import { router } from '../router';
import { RecoilRoot } from 'recoil';
import { ConfigProvider } from '@vkontakte/vkui';
import { SnackbarProvider } from '../context/Snackbar';

export const Init: FC = (props) => {
  return (
    <ApolloProvider client={window.client}>
      <RouterContext.Provider value={router}>
        <RecoilRoot>
          <ConfigProvider scheme="bright_light" isWebView>
            <SnackbarProvider>
              {props.children}
            </SnackbarProvider>
          </ConfigProvider>
        </RecoilRoot>
      </RouterContext.Provider>
    </ApolloProvider>
  );
};
