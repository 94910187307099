import { MouseEvent } from 'react';
import { useRecoilState } from 'recoil';
import { tabAtom } from 'store';
import { Tab } from 'types/tab';

export const useTabs = () => {
  const [activeTab, setTab] = useRecoilState(tabAtom);

  const pushTab = (e: MouseEvent<HTMLDivElement>) => {
    if (!e.currentTarget.dataset.tab) return;
    window.scrollTo(0, 0);
    if (e.currentTarget.dataset.tab === activeTab) return;
    setTab(e.currentTarget.dataset.tab);
  };

  const pushTabByName = (name?: string) => {
    if (!name) return;
    window.scrollTo(0, 0);
    if (name === activeTab) return;
    setTab(name);
  };

  const isFeedTab: boolean = activeTab === Tab.FEED;
  const isGlobalTab: boolean = activeTab === Tab.GLOBAL;
  const isActivityTab: boolean = activeTab === Tab.ACTIVITY;
  const isProfileTab: boolean = activeTab === Tab.PROFILE;
  const isUploadTab: boolean = activeTab === Tab.UPLOAD;

  return {
    tab: activeTab,
    pushTab,
    isFeedTab,
    isGlobalTab,
    isActivityTab,
    isProfileTab,
    isUploadTab,
    pushTabByName,
  };
};
