import { withThrottlingRouter } from '@happysanta/router';
import { getViewProps } from 'partial/props';
import { views, panels, popPageCallback, pages, popPageIfModal, modals } from 'router';
import * as Panels from 'panels';
import * as Modals from 'modals';
import { AppRoot, View, Root, Snackbar, ModalRoot } from '@vkontakte/vkui';
import { InstTabbar } from 'components';
import { useTabs } from 'hooks';
import { classNames } from '@clickpick/shared';
import { useSnackbar } from 'context/Snackbar';
import { useMemo } from 'react';

export const App = withThrottlingRouter(function App(props) {
  const { tab, pushTab } = useTabs();
  const { snackbar, hideSnackbar, popout } = useSnackbar();

  const modal = useMemo(() => (
    <ModalRoot activeModal={props.location.getModalId()}>
      <Modals.LikesModal id={modals.LIKES} onClose={popPageIfModal} settlingHeight={100} />
    </ModalRoot>
  ), [props.location.getModalId()]);

  return (
    <AppRoot>

      <Root activeView={props.location.getViewId()} popout={popout}>

        <View
          {...getViewProps(views.ONBOARDING, props.location, popPageCallback)}
          onTransitionEnd={props.onTransitionEnd}>
          <Panels.Onboarding id={panels.ONBOARDING} />
        </View>

        <View
          {...getViewProps(views.MAIN, props.location, popPageCallback, modal)}
          onTransitionEnd={props.onTransitionEnd}>
          <Panels.Home id={panels.HOME} />
          <Panels.GlobalFeedPanel id={panels.GLOBAL_FEED} />
        </View>

        <View
          {...getViewProps(views.OWNER, props.location, popPageCallback)}
          onTransitionEnd={props.onTransitionEnd}>
          <Panels.OwnerPanel id={panels.OWNER} />
        </View>
      </Root>

      <InstTabbar
        className={classNames({
          'Tabbar__show': props.location.getPageId() === pages.MAIN,
          'InstTabbar': true,
        })}
        selected={tab}
        onClick={pushTab}
      />

      {snackbar !== null && <Snackbar {...snackbar} onClose={hideSnackbar} />}
    </AppRoot>
  );
});
