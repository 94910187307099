import { Div, Subhead, Text } from '@vkontakte/vkui';
import { useState } from 'react';
import { NormalizedPhoto } from 'types/photo';

type PostDescriptionProps = {
  post: NormalizedPhoto;
};

const MAX_LENGTH = 60;

export const PostDescription: React.FC<PostDescriptionProps> = ({ post }) => {
  const [isFull, setIsFull] = useState(false);

  if (!post.caption || post.caption.length === 0) {
    return null;
  }

  if (post.caption.length > MAX_LENGTH && !isFull) {
    return (
      <Div className="Post__description">
        <Subhead weight="3">
          <Text weight="medium">{post.user.screenName}</Text>
          {`${post.caption.substring(0, MAX_LENGTH)}... `}
          <Text
            weight="medium"
            onClick={() => setIsFull(true)}
          >
            ещё
          </Text>
        </Subhead>
      </Div>
    );
  }

  return (
    <Div className="Post__description">
      <Subhead weight="3">
        <Text weight="medium">{post.user.screenName}</Text>
        {post.caption}
      </Subhead>
    </Div>
  );
};
