import { useState } from 'react';

import { useLikePhotoMutation, useUnlikePhotoMutation } from 'generated/graphql';

import { NormalizedPhoto } from 'types/photo';
import { BridgePlus } from '@happysanta/bridge-plus';

export const useLikes = (post: NormalizedPhoto) => {
  const [isLiked, setIsLiked] = useState<boolean>(post.isLiked);
  const [likes, setLikes] = useState<number>(post.likesCount);

  const [likeQuery] = useLikePhotoMutation();
  const [unlikeQuery] = useUnlikePhotoMutation();

  const handleLike = () => {
    setIsLiked(true);

    likeQuery({ variables: { innerId: post.innerId } })
      .then(() => {
        BridgePlus
          .tapticNotificationOccurred({ type: 'success' })
          .catch((ex) => console.error('[Like Taptic]', ex));
        setLikes(likes + 1);
      })
      .catch(() => {
        setIsLiked(false);
      });
  };

  const handleUnlike = () => {
    setIsLiked(false);

    unlikeQuery({ variables: { innerId: post.innerId } })
      .then(() => {
        BridgePlus
          .tapticNotificationOccurred({ type: 'success' })
          .catch((ex) => console.error('[Unlike Taptic]', ex));
        setLikes(likes > 0 ? likes - 1 : 0);
      })
      .catch(() => {
        setIsLiked(true);
      });
  };

  const handleReaction = () => isLiked ? handleUnlike() : handleLike();

  return { likes, isLiked, handleReaction };
};
