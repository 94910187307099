import './styles';
import { AnyFunction, isProd } from '@clickpick/shared';
import config from './config';
import { router } from './router';
import { initView } from './partial/view';
import { mount } from './partial/mount';
import { ApolloClient, InMemoryCache } from '@apollo/client';

((fn: AnyFunction) => {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
})(() => {
  window.client = new ApolloClient({
    uri: config.endpoint,
    cache: new InMemoryCache(),
    headers: {
      Authorization: `VK ${isProd ? btoa(window.location.search.slice(1)) : config.sign}`,
    },
  });

  router.start();

  initView();
  mount();
});
