import { Button, Panel, PanelHeader, PanelHeaderBack, Title } from '@vkontakte/vkui';
import { useExplorePhotos } from '../../hooks/useExplorePhotos';
import { FC } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { BasePanelProps } from '../../types/base';
import { FeedShort } from '../../components/FeedShort/Feed';
import { useRouter } from '@happysanta/router';

export const GlobalFeedPanel: FC<BasePanelProps> = ({ ...props }) => {
  const { fetch, store, hasMore, isLoading, activePhoto } = useExplorePhotos();
  const router = useRouter();

  const [loadRef] = useInfiniteScroll({
    onLoadMore: fetch,
    hasNextPage: hasMore,
    loading: isLoading,
  });

  return (
    <Panel {...props}>
      <PanelHeader separator={false} left={
        <PanelHeaderBack
          onClick={() => router.popPage()}
        />
      }>
        <Title weight="1" level="1">Шнапстер</Title>
      </PanelHeader>
      <FeedShort feed={store} activePhoto={activePhoto} />
      {hasMore && (
        <Button
          getRootRef={loadRef}
          stretched
          size="l"
          loading={isLoading}
          mode="tertiary"
          onClick={() => fetch()}
        >
          Загрузить ещё
        </Button>
      )}
    </Panel>
  );
};
