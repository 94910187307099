import { ActionSheet, ActionSheetItem, Button, Div, Group, PanelHeader, Placeholder, PullToRefresh, Title } from '@vkontakte/vkui';
import { KeepAlive, ProfileInfo, ViewSelector } from 'components';
import { useFriends, useMe, useTabs } from 'hooks';
import { useEffect, useState } from 'react';
import { useOwnerPhotos } from '../../hooks/useOwnerPhotos';
import { FeedShort } from '../../components/FeedShort/Feed';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Grid from '../../components/Grid/Grid';
import { useOwnerInfoQuery } from '../../generated/graphql';
import { BridgePlus } from '@happysanta/bridge-plus';
import { Tab } from '../../types/tab';
import { useSnackbar } from '../../context/Snackbar';

export const ProfilePanel: React.FC = () => {
  const { setPopout } = useSnackbar();
  const { pushTab } = useTabs();
  const [tab, setTab] = useState<'grid' | 'feed'>('grid');
  const { me } = useMe();
  const { store, hasMore, fetch, isLoading, refresh } = useOwnerPhotos(me.id);
  const { friends } = useFriends();
  const userInfo = useOwnerInfoQuery({
    variables: {
      friendIds: friends.map((friend) => {
        return friend.id;
      }),
      ownerId: me.id,
    },
  });

  const [loadRef] = useInfiniteScroll({
    onLoadMore: fetch,
    hasNextPage: hasMore,
    loading: isLoading,
  });

  useEffect(() => {
    fetch();
  }, []);

  const handleShare = () => {
    BridgePlus.send('VKWebAppShare', {
      link: `https://vk.com/app8119814#profile/${me.id}`,
    });
  };

  const handleStoryShare = () => {
    const imgUrl = 'https://ny2021.hb.bizmrg.com/insta-share-2.png';

    BridgePlus.send('VKWebAppShowStoryBox', {
      background_type: 'image',
      url: imgUrl,
      attachment: {
        type: 'url',
        url: `https://vk.com/app8119814#profile/${me.id}`,
        text: 'open',
      },
    });
  };

  const handleGridClick = (innerId: number) => {
    if (BridgePlus.supports('VKWebAppShowImages')) {
      const index = store.findIndex((photo) => {
        return photo.innerId === innerId;
      });

      BridgePlus.send('VKWebAppShowImages', {
        images: store.map((photo) => {
          return photo.photoUrl;
        }),
        start_index: index,
      });
    }
  };

  const closeShare = () => {
    setPopout(null);
  };

  const handleOpenShare = () => {
    setPopout(<ActionSheet
      onClose={closeShare}
      iosCloseItem={
        <ActionSheetItem autoclose mode="cancel">
          Отменить
        </ActionSheetItem>
      }
    >
      <ActionSheetItem autoclose onClick={handleShare}>
        Отправить ссылку
      </ActionSheetItem>
      <ActionSheetItem autoclose onClick={handleStoryShare}>
        Запостить в сториз
      </ActionSheetItem>
    </ActionSheet>);
  };

  return (
    <>
      <PanelHeader className="Profile__header">
        <Title level="1" weight="1">{me.screen_name}</Title>
      </PanelHeader>
      <PullToRefresh onRefresh={refresh} isFetching={isLoading}>
        <Div>
          {userInfo?.data?.ownerInfo && (
            <ProfileInfo
              isMe={true}
              user={me}
              friendsCount={userInfo.data.ownerInfo.friends}
              photosCount={userInfo.data.ownerInfo.photos}
              likesCount={userInfo.data.ownerInfo.likes}
              onShare={handleOpenShare}
            />
          )}
          <Group separator="hide">
            <ViewSelector
              selected={tab}
              onSelect={(value) => setTab(value)}
            />
          </Group>

          <KeepAlive mounted={tab === 'feed'} as="section">
            {store.length === 0 && !hasMore ? (
              <Placeholder action={<Button onClick={pushTab} data-tab={Tab.UPLOAD}>Добавить фото</Button>}>
                Пока здесь ничего нет
              </Placeholder>
            ) : (
              <FeedShort
                feed={store}
              />
            )}
            {hasMore && (
              <Button
                getRootRef={loadRef}
                stretched
                size="l"
                loading={isLoading}
                mode="tertiary"
                onClick={() => fetch()}
              >
                Загрузить ещё
              </Button>
            )}
          </KeepAlive>

          <KeepAlive mounted={tab === 'grid'} as="section">
            {store.length === 0 && !hasMore ? (
              <Placeholder action={<Button onClick={pushTab} data-tab={Tab.UPLOAD}>Добавить фото</Button>}>
                Пока здесь ничего нет
              </Placeholder>
            ) : (
              <Grid
                photos={store}
                onClick={handleGridClick}
              />
            )}
            {hasMore && (
              <Button
                getRootRef={loadRef}
                stretched
                size="l"
                loading={isLoading}
                mode="tertiary"
                onClick={() => fetch()}
              >
                Загрузить ещё
              </Button>
            )}
          </KeepAlive>
        </Div>
      </PullToRefresh>
    </>
  );
};
